.login-modal {
  // max-width: 30% !important;
  // min-height: 382px;
  .modal-header {
    border-bottom: 0px solid #dee2e6;
    padding: 1rem 1em 1rem 1rem !important;
    display: inline-block;
  }
  .header-section {
    display: flex;
    justify-content: space-between !important;
  }
  h3 {
    font-size: 25px;
    text-align: center;
    font-weight: 500;
  }
  .close-icon {
    cursor: pointer;
    font-size: 25px;
  }
  .modal-content {
    border-radius: 16px !important;
    .main-agileits {
      width: 100% !important;
    }
  }
}
