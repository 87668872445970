.hs-header {
  .dropdown-item {
    a {
      color: #212529;
    }
  }
}

.logo {
  text-align: center;
  justify-content: center;
}
.admin-logo {
  width: 90px;
  position: relative;
  top: 10px;
  border-radius: 04px;
}

.logo-admin-text {
  position: relative;
  top: 14px;
  font-weight: 600;
  font-size: 20px;
}
.sb-logo {
  width: 255px;
  height: 138px;
  left: 0px;
  top: 0px;
  // background: #fff;
  // border-bottom: 3px solid #ddd;
  position: relative;
  background: #121725;
}
.sb-logo .logo-text {
  position: absolute;
  top: 32px;
  left: 48px;
  color: #fff;
  font-weight: 900;
  font-size: 60px;
}
.admin-app {
  float: left;
  height: auto;
  width: 100%;
}
.admin-fluid {
  padding: 0 !important;
}
.side-nav-main {
  width: 255px;
  float: left;
  // background: #fff;
  box-shadow: 1px 0 80px rgba(0, 0, 0, 0.8);
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  min-height: 100vh;
  position: relative;
  transition: 0.2s ease-out;
  background: #121725;
  display: flex;

  .side-nav {
    .side-nav__devider {
    }

    ul {
      list-style: none;
      padding: 0;
      list-style: none;
      padding: 0;
      top: 146px;
      background: #121725;
      .snm-vendor-head {
        font-family: robotoCondensed-regular;
        color: #000000 !important;
        padding: 0px 0px 16px;
      }

      .side-menu--active {
        border-bottom: none !important;
        color: #fff !important;
        border-bottom: 1px solid rgba(107, 108, 109, 0.08);
        -webkit-transition: border-left-color 0.3s ease,
          background-color 0.3s ease;
        transition: border-left-color 0.3s ease, background-color 0.3s ease;
        border-radius: 0 0px 0px 0;
        margin: 2px 0px;
        border-left: 4px solid #0059a3;
        // background: #f75d34;
        background: #d8203b;
        padding: 10px 22px 10px !important;
      }

      .side-menu:hover {
        border-bottom: none !important;
        color: #fff;
        border-bottom: none !important;
        -webkit-transition: border-left-color 0.3s ease,
          background-color 0.3s ease;
        transition: border-left-color 0.3s ease, background-color 0.3s ease;
        border-radius: 0 0px 0px 0;
        padding: 10px 22px 10px !important;
        border-left: 4px solid #0059a3;
        // background: #f75d34;
        background: #d8203b;
      }
      .side-menu {
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-bottom: 0.25rem;
        position: relative;
        // color: #495584;
        color: #b8c7ce;
        font-family: robotoCondensed-regular;
        position: relative;
        padding: 10px 26px 10px;
        margin: 2px 0px;
        text-decoration: none;
        .fa-lg {
          font-size: 18px !important;
        }

        .side-menu__icon {
          height: 50px;
          display: flex;
          align-items: center;
          margin-bottom: 0.25rem;
          position: relative;
        }
        .sidebar-icons {
          font-size: 23px;
          position: absolute;
          margin-left: 25px;
        }
        span.dropdown-symbol {
          position: absolute;
          right: 20px;
          margin-left: 0 !important;
        }
        .side-menu__title {
          width: 100%;
          margin-left: 0.75rem;
          display: flex;
          align-items: center;
        }
      }

      li {
      }
    }
  }
}

.sb-mc {
  width: calc(100% - 255px);
  float: left;
  min-height: 100vh;
  background-color: #ffff !important;

  .admin-main-content {
    padding-bottom: 30px;
    padding: 25px;
    overflow-y: scroll;
  }
}
