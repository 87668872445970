// All fonts to be added here..

$font-family-sans-serif: 'Montserrat', 'Segoe UI', Roboto, 'Helvetica Neue',
  Arial, sans-serif;
$font-family-content: 'Rubik', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  sans-serif;
$font-size-root: 16px;

@font-face {
  font-family: roboto-black;
  src: url('./roboto/Roboto-Black.ttf');
}
@font-face {
  font-family: roboto-medium;
  src: url('./roboto/Roboto-Medium.ttf');
}
@font-face {
  font-family: roboto-blackitalic;
  src: url('./roboto/Roboto-BlackItalic.ttf');
}
@font-face {
  font-family: roboto-bold;
  src: url('./roboto/Roboto-Bold.ttf');
}
@font-face {
  font-family: roboto-bolditalic;
  src: url('./roboto/Roboto-BoldItalic.ttf');
}

@font-face {
  font-family: robotoCondensed-italic;
  src: url('./Roboto_Condensed/RobotoCondensed-Italic.ttf');
}
@font-face {
  font-family: robotoCondensed-bolditalic;
  src: url('./Roboto_Condensed/RobotoCondensed-BoldItalic.ttf');
}
@font-face {
  font-family: robotoCondensed-light;
  src: url('./Roboto_Condensed/RobotoCondensed-Light.ttf');
}
@font-face {
  font-family: robotoCondensed-lightItalic;
  src: url('./Roboto_Condensed/RobotoCondensed-LightItalic.ttf');
}
@font-face {
  font-family: robotoCondensed-regular;
  src: url('./Roboto_Condensed/RobotoCondensed-Regular.ttf');
}
@font-face {
  font-family: robotoCondensed-bold;
  src: url('./Roboto_Condensed/RobotoCondensed-Bold.ttf');
}


@font-face {
  font-family: rubik-black;
  src: url("./Rubik//static/Rubik-Black.ttf");
}
@font-face {
  font-family: rubik-regular;
  src: url("./Rubik/static/Rubik-Regular.ttf");
}
@font-face {
  font-family: rubik-medium;
  src: url("./Rubik//static/Rubik-Medium.ttf");
}
@font-face {
  font-family: rubik-blackitalic;
  src: url("./Rubik/static/Rubik-Italic.ttf");
}
@font-face {
  font-family: rubik-bold;
  src: url("./Rubik/static/Rubik-Bold.ttf");
}