.loading-page {
  display: flex;
  justify-content: center;
  margin: 250px 0px 250px 0px;
  .ball-clip-rotate > div {
    border: 2px solid #0059a3;
    height: 150px;
    width: 150px;
    border-radius: 100%;
    margin: 2px;
    animation-fill-mode: both;
    border-bottom-color: transparent;
    background: transparent !important;
    display: inline-block;
    animation: rotate 0.75s 0s linear infinite;
  }
}
