.footer-section {
  .footer-nav {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    // background: #465166;
    background: #121725;
    color: #fff;
  }
  footer {
    background: #fff;
  }
  .container {
    width: 98%;
    max-width: 1280px;
  }

  .footer-grid-icon {
    padding-top: 42px;
    padding-bottom: 42px;
  }
  .icon-img {
    text-align: center;
    height: 70px;
    img {
      height: 60px;
    }
  }
  .title-text-section {
    text-align: center;

    .title {
      font-size: 22px;
      line-height: 27px;
      color: #24272c;
      margin-bottom: 0;
      font-weight: 600;
    }
    p {
      font-size: 15px;
      line-height: 22px;
      color: rgba(36, 39, 44, 0.5);
      font-weight: 500;
    }
  }

  .newsletter-area {
    text-align: center;
    background: #d8203b;
    color: #fff;
    padding: 60px 0;
  }
  .newsletter-headline {
    margin-bottom: 30px;
  }
  .newsletter-headline h2 {
    font-size: 36px;
    font-weight: 700;
    margin: 0;
    padding-bottom: 10px;
  }
  .newsletter-submit .newsletter-input {
    width: 60%;
    height: 50px;
    height: 47px;
    text-indent: 20px;
    padding-right: 10px;
    color: #2a2a2a;
    display: inline-block;
    border-radius: 0;
  }
  .newsletter-input {
    line-height: normal;
  }
  .newsletter-submit .newsletter-btn {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    width: 20%;
    height: 50px;
    border-bottom: 1px solid #540a0a;
    background: #540a0a;
    color: #fff;
    margin-left: -5px;
    border-radius: 0;
  }
  .newsletter-submit .newsletter-btn:hover {
    background: #000;
    border-bottom: 1px solid #000;
  }
  .newsletter-input:hover,
  .newsletter-input:focus,
  .newsletter-input:hover,
  .newsletter-input:focus {
    box-shadow: none;
    border: none;
  }
  .error {
    font-size: 12px;
    margin-top: 5px;
    display: block;
    position: absolute;
    left: 143px;
    color: #fff !important;
  }
  .newsletter-submit {
    .loading-data {
      display: flex;
      justify-content: center;
      margin-top: -20px;
      svg {
        fill: #fff !important;
      }
    }
  }
  footer nav {
    padding: 46px 0 50px;
    text-align: left;
  }
  footer nav .title {
    font-size: 16px;
    font-weight: 700;
    // color: #24272c;
    color: #fff;
    padding: 7px 0 9px;
    margin-bottom: 0;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  footer nav li {
    line-height: 2.3;
  }
  li {
    list-style: none;
  }
  footer nav li a,
  footer nav li span,
  footer nav li .link-button {
    display: inline-block;
    text-align: left;
    color: #fff;
    font-size: 15px;
    -webkit-transition: box-shadow 0.3s ease-in;
    transition: box-shadow 0.3s ease-in;
    cursor: pointer;
    font-weight: 500;
    background: transparent;
    border: none;
    padding: 0;
  }
  .footer .link-button:hover,
  .footer .link-button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
    border: none;
  }
  .footer .SocialIcons ul li {
    display: inline-block;
    padding-right: 16px;
  }

  .social-icon {
    font-size: 20px;
  }
  .footerbottom {
    // background: #465166;
    background: #121725;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    height: 67px;
    padding-top: 13px;
  }
  .copyRight {
    font-size: 15px;
    // color: rgba(36, 39, 44, 0.5);
    color: #fff;
    margin-top: 9px;
    font-weight: 500;
  }

  //..Share Icon Style ..//
  #st-2.st-left {
  }
  .st-left-open {
    left: 0px;
    // display: block;
    display: grid;
  }
  .st-left-heddin {
    left: -48px;
    display: none;
  }
  #st-2 {
    font-family: 'Helvetica Neue', Verdana, Helvetica, Arial, sans-serif;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    backface-visibility: hidden;
    position: fixed;
    opacity: 1;
    text-align: left;
    top: 160px;
    z-index: 94034;
    display: block;
  }
  #st-2.st-has-labels .st-btn:hover {
    width: 120px;
  }
  #st-2 .st-btn:hover {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  .st-btn {
    display: inline-block;
  }
  .react-share__ShareButton:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
  #st-2 .st-btn[data-network='facebook'] {
    background-color: #4267b2;
  }
  #st-2 .st-btn[data-network='twitter'] {
    background-color: #55acee;
  }
  #st-2 .st-btn[data-network='pinterest'] {
    background-color: #cb2027;
  }
  #st-2 .st-btn[data-network='email'] {
    background-color: #7d7d7d;
  }
  #st-2 .st-btn[data-network='sharethis'] {
    background-color: #95d03a;
  }
  #st-2 .st-btn.st-first {
    border-top: none;
    border-top-right-radius: 4px;
  }
  #st-2 .st-btn {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: NaNpx;
    height: 48px;
    line-height: 24px;
    margin-bottom: 0;
    opacity: 1;
    overflow: hidden;
    padding: 12px;
    position: relative;
    text-align: left;
    top: 0;
    vertical-align: top;
    white-space: nowrap;
    width: 48px;
  }
  #st-2 > div {
    clear: left;
    float: left;
  }
  .st-btn:hover > img {
    margin-left: 5px;
  }
  .st-btn .react-share__ShareButton:hover > img {
    margin-left: 5px;
  }
  .st-btn .react-share__ShareButton > img {
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    height: 24px;
    margin-left: 0;
    vertical-align: top;
    width: 24px;
  }
  .st-btn > img {
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    height: 24px;
    margin-left: 0;
    vertical-align: top;
    width: 24px;
  }
  .st-btn .react-share__ShareButton > span {
    color: #fff;
  }
  .st-btn[data-network='facebook'] > span {
    color: #fff;
  }
  .st-btn .react-share__ShareButton:hover > span {
    opacity: 1;
    display: inline-block;
    left: 0;
    filter: alpha(opacity=100);
  }
  .st-btn:hover > span {
    opacity: 1;
    display: inline-block;
    left: 0;
    filter: alpha(opacity=100);
  }
  .st-btn .react-share__ShareButton > span {
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    left: -35px;
    letter-spacing: 0.5px;
    opacity: 0;
    padding: 0 6px;
    position: relative;
    vertical-align: top;
    filter: alpha(opacity=0);
  }
  .st-btn > span {
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    left: -35px;
    letter-spacing: 0.5px;
    opacity: 0;
    padding: 0 6px;
    position: relative;
    vertical-align: top;
    filter: alpha(opacity=0);
  }
  #st-2 .st-toggle {
    background: #ccc;
    border-bottom-right-radius: 4px;
    color: white;
    cursor: pointer;
    line-height: 24px;
    position: relative;
    text-align: center;
    width: 48px;
    position: fixed;
    opacity: 1;
    z-index: 94034;
    top: 400px;
  }
}
@media (max-width: 599px) {
  .app-button {
    margin: 15px 0px;
  }
}
.share-icon-modal {
  .modal-header {
    border-bottom: 0px solid #dee2e6;
    padding: 1rem 1em 0rem 1rem !important;
    display: inline-block;
  }
  .header-section {
    display: flex;
    justify-content: space-between !important;
  }
  h3 {
    font-size: 25px;
    text-align: center;
    font-weight: 500;
  }
  .close-icon {
    cursor: pointer;
    font-size: 25px;
  }
  .modal-content {
    border-radius: 16px !important;
    .main-agileits {
      width: 100% !important;
    }
  }
  .social-media-btn {
    padding: 8px 5px !important;
    text-decoration: none;
    // display: block;
  }
  .react-share__ShareButton:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
}
