.header-new-container {
  .top-header {
    // background: #555;
    background: #121725;
    line-height: 40px;
  }
  .top-header-left p,
  .top-header-right,
  .top-header-right a,
  .top-header-right .link-tag {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    margin-right: 15px;
    text-decoration: none;
    cursor: pointer;
  }
  .top-header-left .top-header-icon,
  .top-header-right .top-header-icon {
    margin-right: 5px;
  }
  .top-header-right {
    float: right;
  }
  .top-header-right a,
  .top-header-right .link-tag {
    margin-right: 20px;
  }
  .loading-icon {
    position: absolute;
    z-index: 999;
    .loading-data {
      display: flex;
      justify-content: center;
      margin-top: -42px;
    }
  }

  .logo-img {
    height: 100% !important;
    padding: 0px;
    width: 80px;
    border-radius: 04px;
  }
  .logo-text {
    height: 100%;
    font-weight: 800;
    padding-left: 5px;
    // color: #465166 !important;
    color: #fff !important;
  }
  .navbar-brand {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .navbar {
    width: 100%;
    // background-color: #fff !important;
    background: #d8203b !important;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.05);
    padding: 0rem 0rem !important;
  }

  .navbar {
    .collapse.navbar-collapse {
      .navbar-nav {
        .nav-link {
          &.active {
            // color: #f75d34 !important;
            color: #fff !important;
            text-decoration: none !important;
            cursor: pointer !important;
            background: #bc132c;
          }
        }
      }
    }
  }
  .nav-link {
    display: block;
    padding: 0.5rem 0.9rem !important;
    padding: 1rem 1rem 1rem 1rem !important;
    font-weight: 700;
    // color: #465166 !important;
    color: #fff !important;
    font-size: 16px;
  }

  .navbar-light .navbar-nav .nav-link:hover {
    // color: #f75d34 !important;
    color: #fff !important;
    text-decoration: none;
    cursor: pointer;
    background: #bc132c;
  }
}
@media (max-width: 599px) {
  .navbar {
    padding: 0.5rem 0rem 0.5rem 0rem !important;
  }
}
