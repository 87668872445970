.header-container-admin {
  background-color: #fafbfc;
  box-shadow: 0 3px 10px rgba(27, 4, 2, 0.3);

  .header-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    height: 50px;
  }
  .customer-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    height: 70px;
  }
  
  .login-signup {
    font-size: 17px;
    margin-bottom: 0;
    font-weight: bold;
    cursor: pointer;
    color: #495584;
    font-family: robotoCondensed-regular;
    a {
      text-decoration: none;
      color: #495584;
    }
  }

}
