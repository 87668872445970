@import './components/assets/scss/colors.scss';
@import './components/assets/fonts/fonts.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'font-awesome/css/font-awesome.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';
@import '@fortawesome/fontawesome-free/css/all.min.css';
// @import url(sanitize.css);

.App {
  color: $text-color;
  background-color: $bg-color;
  font-family: robotoCondensed-regular;
}

@media all and (min-width: 960px) {
  body {
    font-size: 18px;
  }
}

@media all and (max-width: 959px) and (min-width: 600px) {
  body {
    font-size: 16px;
  }
}

@media all and (max-width: 599px) and (min-width: 320px) {
  body {
    font-size: 12px;
  }
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: robotoCondensed-regular;
  width: 100%;
  height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-print-color-adjust: exact;
  color: #555;
  background-color: #f8f8f8 !important;
  font-size: 1rem;
  .btn-secondary.dropdown-toggle {
    background-color: #0059a3 !important;
    color: #fff;
    margin: 0;
    font-family: robotoCondensed-bold;
    box-shadow: none;
    padding: 8px 6px;
  }
  .react-bootstrap-table-page-btns-ul.pagination {
    float: right;
  }
  .react-bs-table {
    overflow-x: scroll;
  }
  .pagination .page-item.active .page-link {
    color: #fff !important;
    font-family: robotoCondensed-bold;
    background: #0059a3 !important;
    box-shadow: none !important;
  }

  .pagination .page-item .page-link {
    float: right;
    color: #fff !important;
    background: #0059a3 !important;
    font-family: robotoCondensed-bold;
  }
  .react-bootstrap-table table {
    width: 100%;
    overflow-x: auto;
  }
  .add-main-btn {
    padding: 5px 10px;
    background: #0059a3 !important;
    border: none !important;
    border-radius: 4px;
    font-family: robotoCondensed-bold;
    text-transform: uppercase;
    span {
      font-size: 14px;
      margin-right: 3px;
    }
  }
  .cars-table {
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
      0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
      0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
      0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
    border: none;
    border-radius: 10px;
  }
  .edit-btn {
    margin-right: 8px;
    padding: 5px 24px;
    font-family: robotoCondensed-bold;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: #0059a3 !important;
    border: none !important;
    color: #fff;
  }
  .add-btn {
    margin-right: 8px;
    padding: 5px 24px;
    font-family: robotoCondensed-bold;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: #f75d34 !important;
    border: none !important;
    color: #fff;
  }
  .delete-btn {
    margin-right: 8px;
    padding: 5px 24px;
    font-family: robotoCondensed-bold;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: #ec4646 !important;
    border: none;
  }
  .success-btn {
    margin-right: 8px;
    padding: 5px 24px;
    font-family: robotoCondensed-bold;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: #3ac47d !important;
    border: none;
    color: #fff;
  }
  .add-modal-btn {
    margin-right: 8px;
    padding: 5px 24px;
    background: #0059a3 !important;
    border: none !important;
    font-family: robotoCondensed-bold;
    text-transform: uppercase;
    border-radius: 4px;
  }
  .cancel-modal-btn {
    background-color: #f75d34 !important;
    font-family: robotoCondensed-bold;
    text-transform: uppercase;
    box-shadow: none !important;
    padding: 5px 24px;
    border-radius: 4px;
    border: none !important;
  }
  .delete-modal-btn {
    background-color: #f75d34 !important;
    font-family: robotoCondensed-bold;
    text-transform: uppercase;
    box-shadow: none !important;
    padding: 5px 24px;
    border-radius: 4px;
    border: none !important;
    margin-right: 8px;
  }
  .error {
    font-size: 12px;
    margin-top: 5px;
    color: red;
    margin-left: 5px;
  }
  .label-name {
    font-family: robotoCondensed-regular;
    color: black;
  }
  .input-name {
    background: #f3f3f3 !important;
    border-radius: 8px;
    height: 40px;
    border: 0;
    color: black !important;
    padding: 20px 15px !important;
    box-shadow: none !important;
    font-size: 14px;
  }
  .input-file {
    background: #f3f3f3 !important;
    border-radius: 8px;
    height: 40px;
    border: 0;
    color: black !important;
    padding: 7px 15px 20px 15px !important;
    box-shadow: none !important;
    font-size: 14px;
  }
  &.body-active-menu.home {
    overflow-y: hidden;
  }
  // --modal-header-bgcolor: #85603f;
  --modal-header-bgcolor: #0059a3;
  --modal-header-text-color: #fff;
  --add-btn-textcolor: white;
  --update-btn-bgcolor: #01a9b4;
  --update-btn-textcolor: white;
  --sidebar-bgcolor: #0059a3;
  --sidebar-textcolor: white;
  --header-bgcolor: #fafbfc;
  --cancel-color: #ec4646;
}

body {
  overflow-y: auto;
  padding: 0 !important;
  margin: 0 !important;
  overflow-x: hidden;
}

a:focus,
a:active {
  outline: none;
}
.add-modal {
  max-width: 38%;

  .modal-header {
    background: var(--modal-header-bgcolor);
    margin: 0 !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    .close {
      outline: none !important;
      color: white;
    }
    h5 {
      margin: 0 !important;
      font-family: robotoCondensed-bold;
      font-size: 18px;
    }
  }
  .modal-content {
    border-radius: 12px !important;
    box-shadow: none !important;
  }
  .modal-title {
    color: var(--modal-header-text-color);
  }

  .modal-body {
    padding: 20px 30px;
  }
  .label-name {
    color: black;
    font-family: robotoCondensed-regular;
  }
  .input-name {
    background: #f3f3f3 !important;
    border-radius: 8px;
    height: 40px;
    border: 0;
    color: black !important;
    padding: 7px 15px !important;
    box-shadow: none !important;
  }
  .input-textarea {
    background: #f3f3f3 !important;
    border-radius: 8px;
    height: 120px;
    border: 0;
    color: black !important;
    padding: 7px 15px !important;
    box-shadow: none !important;
  }
  .button-div {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .table-img {
    max-width: 100%;
    height: 80px;
    object-fit: cover;
  }
}
.delete-modal {
  max-width: 35%;
  .modal-header {
    background: var(--modal-header-bgcolor);
    margin: 0 !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    .close {
      outline: none !important;
      color: white;
    }
    h5 {
      margin: 0 !important;
      font-family: robotoCondensed-bold;
      font-size: 18px;
    }
  }
  .modal-content {
    border-radius: 12px !important;
    box-shadow: none !important;
  }
  .modal-title {
    color: var(--modal-header-text-color);
  }

  .modal-body {
    padding: 20px 30px;
  }
  .button-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 599px) {
  //.. Customer Side  Start  ..//
  .sell-cars {
    .card_box {
      width: 100% !important;
    }
    .section .contact {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      height: 100%;
      margin: auto;
      width: 100% !important;
    }
    .timeline {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
    .timestamp {
      margin-bottom: 20px;
      padding: 0px 25px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 100;
    }
    .status {
      padding: 15px 5px !important;
      display: flex;
      justify-content: center;
      border-top: 2px solid #d6dce0;
      position: relative;
      transition: all 200ms ease-in;
    }
    .status:before {
      content: '';
      width: 12px !important;
      height: 12px !important;
      background-color: white;
      border-radius: 25px;
      border: 1px solid #ddd;
      position: absolute;
      top: -7px;
      left: 42%;
      transition: all 200ms ease-in;
    }
    .status .numberCircle {
      border-radius: 50%;
      padding: 5px !important;
      background: #fff;
      border: 2px solid #666;
      text-align: center;
      font-size: 10px !important;
      width: 25px !important;
      height: 25px !important;
    }
    .li.complete .status .numberCircle {
      border-radius: 50%;
      padding: 5px;
      background: #fff;
      border: 2px solid #2176ae;
      color: #2176ae;
      text-align: center;
      font-size: 10px !important;
      width: 25px !important;
      height: 25px !important;
    }
    .registration-year-section {
      padding: 35px 0px 70px 0px;
      width: 100% !important;
    }
  }
  //.. home page  ..//
  .img-banner-section {
    margin-top: 36px;
    background: #eaeff3;
    background-image: none !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100%;
  }

  //.. Footer app icon ..//
  .appholder {
    img.img-fluid {
      width: 130px !important;
    }
  }
  .submit-detail-box {
    width: 100% !important;
    height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 4px 22px -8px #0004;
    display: flex;
    overflow: hidden;
    .right {
      display: none;
    }
    .left {
      width: 100% !important;
    }

    .left .contact {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      height: 100%;
      width: 100% !important;
      margin: auto;
    }
  }

  //.. Customer Login Popup ..//
  .login .verify {
    right: 0px !important;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: rgba(70, 81, 102, 0.3);
    top: 0;
    cursor: default;
    color: #d8203b;
    cursor: pointer !important;
    position: absolute;
    height: 48px;
    line-height: 65px;
    background: transparent !important;
    border: none !important;
  }
  //.. Dealer Page  ..//
  .banner-dealer-section {
    .banner-inner-section {
      padding: 15px 10px 15px 10px !important;
    }
    .banner-text-section {
      display: flex;
      justify-content: space-between;
    }
    .banner-text-section h3 {
      font-weight: 700;
      font-size: 20px !important;
      line-height: 25px !important;
      margin: 0 0 1px !important;
    }
  }
  .banner-customer-section {
    .banner-inner-section {
      padding: 15px 10px 15px 10px !important;
    }
    .banner-text-section {
      display: flex;
      justify-content: space-between;
    }
    .banner-text-section h3 {
      font-weight: 700;
      font-size: 20px !important;
      line-height: 25px !important;
      margin: 0 0 1px !important;
    }
  }
  .form-inner-section {
    padding: 0px 0px !important;
  }
  .profile-container {
    margin: 20px 0px 60px 0px !important;
  }
  //.. Customer Side  End  ..//

  //.. Admin Side  Start  ..//
  .admin-login {
    .login_box {
      width: 100% !important;
      height: 600px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: 10px;
      box-shadow: 1px 4px 22px -8px #0004;
      display: flex;
      overflow: hidden;
    }
    .left .contact {
      width: 100% !important;
    }
    .login_box .left {
      width: 100% !important;
      height: 100%;
      padding: 25px 25px;
    }
    .login_box .right {
      display: none;
    }
  }
  //.. Admin Side End    ..//

  .login-modal {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0rem !important;
  }
  .add-car-modal {
    width: 100%;
    max-width: 100% !important;
    min-width: 100% !important;
    margin: 0rem !important;
  }
  .delete-car-modal {
    width: 100%;
    max-width: 100% !important;
    min-width: 100%;
    margin: 0rem !important;
  }
  .car-detail-modal {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    margin: 0rem !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

.add-car-modal {
  max-width: 60%;
  min-width: 60%;
  .modal-header {
    background: var(--modal-header-bgcolor);
    margin: 0 !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    .close {
      outline: none !important;
      color: white;
    }
    h5 {
      margin: 0 !important;
      font-family: robotoCondensed-bold;
      font-size: 18px;
    }
  }
  .modal-content {
    border-radius: 12px !important;
    box-shadow: none !important;
  }
  .modal-title {
    color: var(--modal-header-text-color);
  }

  .modal-body {
    padding: 20px 30px;
  }
  .label-name {
    color: black;
    font-family: robotoCondensed-regular;
  }
  .checkbox-details {
    margin-left: 20px;
    .check-text-name {
      color: black;
      font-family: robotoCondensed-regular;
    }
  }
  .input-name {
    background: #f3f3f3 !important;
    border-radius: 8px;
    height: 40px;
    border: 0;
    color: black !important;
    padding: 7px 15px !important;
    box-shadow: none !important;
  }
  .input-textarea {
    background: #f3f3f3 !important;
    border-radius: 8px;
    height: 120px;
    border: 0;
    color: black !important;
    padding: 7px 15px !important;
    box-shadow: none !important;
  }
  .css-du05gz-container {
    border-radius: 8px;
  }
  .css-1wrae5t-control {
    background: #f3f3f3 !important;
    border-radius: 8px;
    height: 40px;
    border: 0;
  }
  .input-textarea {
    background: #f3f3f3 !important;
    border-radius: 8px;
    height: 120px;
    border: 0;
    color: black !important;
    padding: 7px 15px !important;
    box-shadow: none !important;
  }
  .button-div {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .edit-image-section {
    display: flex;
    padding: 0px 0px 15px 0px;
    position: relative;
    flex-wrap: wrap;
  }
  .remove-icon {
    margin: 0px 0px 0px 98px;
    color: red;
    font-size: 20px;
    cursor: pointer;
  }
  .car-image-grid {
    width: 130px;
    height: 90px;
    padding: 0px 15px 0px 0px;
    img.edit-car {
      height: 100%;
      width: 100%;
    }
  }

  .errorMessage {
    padding: 7px 20px;
    margin: 0 auto;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;
    width: 320px;
    display: block;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    text-align: center;
  }
}
.add-bidding-modal {
  max-width: 35%;
  .modal-header {
    background: var(--modal-header-bgcolor);
    margin: 0 !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    .close {
      outline: none !important;
      color: white;
    }
    h5 {
      margin: 0 !important;
      font-family: robotoCondensed-bold;
      font-size: 18px;
    }
  }
  .modal-content {
    border-radius: 12px !important;
    box-shadow: none !important;
  }
  .modal-title {
    color: var(--modal-header-text-color);
  }

  .modal-body {
    padding: 20px 30px;
  }
  .label-name {
    color: black;
    font-family: robotoCondensed-regular;
  }

  .input-name {
    background: #f3f3f3 !important;
    border-radius: 8px;
    height: 40px;
    border: 0;
    color: black !important;
    padding: 7px 15px !important;
    box-shadow: none !important;
  }
  .react-datepicker {
    display: flex;
  }
  .button-div {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .errorMessage {
    padding: 7px 20px;
    margin: 0 auto;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;
    width: 320px;
    display: block;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    text-align: center;
  }
}
.delete-car-modal {
  max-width: 35%;
  .modal-header {
    background: var(--modal-header-bgcolor);
    margin: 0 !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    .close {
      outline: none !important;
      color: white;
    }
    h5 {
      margin: 0 !important;
      font-family: robotoCondensed-bold;
      font-size: 18px;
    }
  }
  .modal-content {
    border-radius: 12px !important;
    box-shadow: none !important;
  }
  .modal-title {
    color: var(--modal-header-text-color);
  }
  .modal-body {
    padding: 20px 30px;
  }
  .button-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.car-detail-modal {
  max-width: 45%;
  min-width: 45%;
  width: 45%;
  .modal-header {
    background: var(--modal-header-bgcolor);
    margin: 0 !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    .close {
      outline: none !important;
      color: white;
    }
    h5 {
      margin: 0 !important;
      font-family: robotoCondensed-bold;
      font-size: 18px;
    }
  }
  .modal-content {
    border-radius: 12px !important;
    box-shadow: none !important;
  }
  .modal-title {
    color: var(--modal-header-text-color);
  }
  .modal-body {
    padding: 20px 20px;
  }
  .button-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  table {
    border-collapse: collapse;
    border-radius: 04px;
    overflow: hidden;
    width: 100%;
    font-family: robotoCondensed-regular;
  }
  th,
  td {
    padding: 1em;
    border-bottom: 2px solid white;
    border: 1px solid #d3d3d3;
  }
  // .car-img-text {
  //   width: 45%;
  // }
  .car-img {
    width: 100%;
  }
  .table-car-img {
    max-width: 100% !important;
    height: 500px;
    background-position: center;
    background-size: cover;
  }
  .carousel .thumbs-wrapper {
    margin: 0px !important;
    overflow: hidden;
  }
  .carousel .thumbs {
    margin: 0px !important;
  }
  .no-data-available {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    font-weight: bold;
    font-size: 30px;
  }
  .aadhar-profile-img {
    max-width: 100%;
    height: 120px;
    object-fit: cover;
  }
}

//..Table Style Start ..//
.table-height {
  height: 315px;
  overflow-x: hidden;
}
.table-container {
  // height: 522px;
  overflow-x: hidden;
}
.table-border-style {
  background: #f8f8f8;
  border-radius: 04px;
  position: relative;
  .edit-btn,
  .delete-btn {
    margin: 0px 5px 0px 0px !important;
  }
  .react-bootstrap-table {
    padding: 0px 0px 0px 0px;
    // overflow-x: scroll;
  }
}
table.table {
  border-radius: 04px;
}
table {
  border-collapse: collapse;
  overflow: hidden;
  width: 100%;
}
thead {
  color: #fff;
  background: #0059a3;
}
table.table th {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
tbody td {
  border-bottom: 0.1px dashed grey !important;
  border-top: 0.1px dashed grey !important;
}
.table th {
  border-bottom: 0.1px dashed grey !important;
}
th,
td {
  padding: 10px 5px 10px 5px !important;
  font-weight: 600;
}

.car-details-card{
  display: flex;
  padding: 10px;
  border: 1px solid #0059a3;
  p{
    margin-bottom: 4px;
    word-break: break-all;
  }
}
#scrollableDiv::-webkit-scrollbar {
  display: none;
}
.infinite-scroll-component{
  .loading-data{
    margin-top: 20px;
  }
  .car-model-detail{
   word-break: break-word;
   font-size: 15px;
   p{
    word-break: break-word;
    font-size: 15px;
   }
  }
}

//..Table Style End ..//
